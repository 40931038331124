import React from 'react';
import ReactPlayer from 'react-player';

const VideoPlayerCard = () => {
  return (
    <div className=' bg-[#171747] flex object-cover justify-center w-full  pt-1 sm:pt-24'>
        <div className=' relative flex justify-center items-center w-full '>
        {/*overlay*/}
        <div className='bg-black/40 h-full w-full absolute flex'>
      <label className=' absolute  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  text-white  font-semibold  text-xl
      flex justify-center w-72  sm:text-4xl sm:w-[550px] md:text-5xl md:w-[750px]   lg:w-[900px] lg:text-6xl
      
       '> Let's build something Together!</label></div>
      <ReactPlayer
        url="https://assets.mixkit.co/videos/preview/mixkit-unlocking-phone-and-typing-17585-large.mp4"
        controls={false}
        playing={true}
        muted={true}
        loop={true}
        width="100%"
        height="100%"
        
        
      />
  </div>
    </div>

  );
};

export default VideoPlayerCard;
