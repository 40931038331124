
import React from 'react';

const MyPageTittle = () => {
  return (
    <div>
      {/* Your page content goes here */}
    </div>
  );
};

const MyPage = () => {

  React.useEffect(() => {
    document.title = 'GetApped333';
 
  }, []);}
  export default MyPage;